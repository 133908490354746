import React from "react";
import backVid from "../../videos/background-vid.mp4";

export default function Background() {
  return (
    <video
      className="z-0 w-screen h-screen object-cover absolute inset-0 bg-gray-300"
      controls={false}
      autoPlay={true}
      muted={true}
      loop={true}
    >
      <source src={backVid} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}
